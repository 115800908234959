import React, {lazy, Suspense} from "react";

// Navigation Styling
import "./Theme.scss";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "./assets/img/logo-long.png";
import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "./components/ScrollToTop";

import ProgressBar from "react-scroll-progress-bar";

// Routing imports
import {BrowserRouter as Router, Link, NavLink, Route, Switch,} from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Product = lazy(() => import("./pages/Product"));
const School = lazy(() => import("./pages/School"));
const Education = lazy(() => import("./pages/Education"));
const Work = lazy(() => import("./pages/Work"));
const Support = lazy(() => import("./pages/Support"));
const Premium = lazy(() => import("./pages/Premium"));
const TermsOfUse = lazy(() => import("./pages/footer-pages/TermsOfUse"));
const CookiePolicy = lazy(() => import("./pages/footer-pages/CookiePolicy"));
const PrivacyPolicy = lazy(() => import("./pages/footer-pages/PrivacyPolicy"));
const GDPR = lazy(() => import("./pages/footer-pages/GDPR"));
const Construction = lazy(() => import("./pages/Construction"));
const Science = lazy(() => import("./pages/Science"));

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    console.log(i18n.language);
  };

  return (
    <Router>
      <ScrollToTop />
      <Suspense>
        <div>
          <ProgressBar bgcolor="#3682D9" className="fixed-top" />
          <Navbar bg="light" expand="lg" className="sticky-top shadow">
            <Container>
              <Link to="/">
                <Navbar.Brand href="#home">
                  <img src={Logo} width="auto" height="48" alt="Wavers logo" />
                </Navbar.Brand>
              </Link>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link>
                    <NavLink
                      to="/"
                      className="inactive-nav-link"
                      activeClassName="active-nav-link"
                      exact
                    >
                      <Trans i18nKey="navlink-1" />
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link>
                    <NavLink
                      to="/product"
                      className="inactive-nav-link"
                      activeClassName="active-nav-link"
                    >
                      <Trans i18nKey="navlink-2" />
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link>
                    <NavLink
                      to="/support"
                      className="inactive-nav-link"
                      activeClassName="active-nav-link"
                    >
                      <Trans i18nKey="navlink-3" className="text-secondary" />
                    </NavLink>
                  </Nav.Link>

                  <Nav.Link>
                    <NavLink
                      to="/premium"
                      className="inactive-nav-link"
                      activeClassName="active-nav-link"
                    >
                      <Trans i18nKey="navlink-4" className="text-secondary" />
                    </NavLink>
                  </Nav.Link>

                </Nav>
                <div className={"text-decoration-none"}>
                  <Button
                    className="ml-4 d-none d-lg-block nav-btn"
                    href={t("website")}
                  >
                    <Trans i18nKey="navlink-login"/>
                  </Button>
                </div>

                <Nav>
                  <NavDropdown
                    title={
                      <FontAwesomeIcon
                        icon={faGlobe}
                        size="lg"
                        className="ml-3 inactive-nav-link "
                      />
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={() => changeLanguage("en")}>
                      EN
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => changeLanguage("dk")}>
                      DK
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/product">
              <Product />
            </Route>
            <Route path="/school">
              <School />
            </Route>
            <Route path="/education">
              <Education />
            </Route>
            <Route path="/work">
              <Work />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route path="/premium">
              <Premium />
            </Route>
            <Route path="/private-policy-app">
              <PrivacyPolicy />
            </Route>
            <Route path="/en/private-policy-app">
              <PrivacyPolicy />
            </Route>
            <Route path="/cookie-policy">
              <CookiePolicy />
            </Route>
            <Route path="/terms-of-conditions-app">
              <TermsOfUse />
            </Route>
            <Route path="/en/terms-of-conditions-app">
              <TermsOfUse />
            </Route>
            <Route path="/GDPR">
              <GDPR />
            </Route>
            <Route path="/background-for-the-design-of-the-wavers-apps-questions">
              <Science />
            </Route>
            <Route path="/construction">
              <Construction />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
